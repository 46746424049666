<template>
  <!-- 素材管理 -->
  <div class="scgl">
    <div class="scgl-row1">
      <span>
        <el-select
          v-model="model.scenarioId"
          style="width: 100px"
          size="mini"
           filterable
          placeholder="关联剧本"
        >
          <el-option label="全部剧本" :value="''"></el-option>
          <el-option
            v-for="(option, oi) in scenarioList"
            :key="oi"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>

        <el-select
          v-model="model.category"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="类别"
        >
          <el-option label="全部类别" :value="''"></el-option>
          <el-option
            v-for="(option, oi) in audioTypeList"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.timeLength"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="时长"
        >
          <el-option label="全部时长" :value="''"></el-option>
          <el-option
            v-for="(option, oi) in audioTimeLengthList"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
        <el-select
          v-model="model.theme"
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="题材"
        >
          <el-option label="全部题材" :value="''"></el-option>
          <el-option
            v-for="(option, oi) in list1"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
      </span>
      <span class="right">
        <el-input
          size="mini"
          style="width: 120px"
          v-model="model.searchKey"
          placeholder="搜索关键词"
        ></el-input>
        <el-button size="mini" style="margin-left: 10px">批量管理</el-button>
      </span>
    </div>

    <div class="scgl-row2">
      <el-card class="scgl-row2-card" v-loading="loading">
        <div class="scgl-card-content">
          <div class="scgl-item" v-for="item in audioList" :key="item.id">
            <AudioItem
              @markhandler="openDialog(item)"
              :isShowMark="true"
              :audioItem="item"
            />
            <div class="item-message">
              <div class="row1">
                {{ item.audioName || "未命名" }}
              </div>
              <div class="row2">
                <!-- <span>{{ item.themeName }}</span> -->
                <span>{{fomatType(item.theme)}}</span>
                <span style="color: rgba(153, 153, 153, 1)">{{
                  timestampToDate(item.createTime)
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>

    <div class="row3">
      <el-pagination
        :total="total"
        :current-page.sync="model.currPage"
        :page-size.sync="model.limit"
        layout="prev, pager, next"
      ></el-pagination>
    </div>

    <UpdateAudioDialog ref="audioDialog" @updateAudio="getAudioList" :updadeItem="updadeItem" />
  </div>
</template>

<script>
import AudioItem from "@compts/common/audioItem.vue";
import UpdateAudioDialog from "./compt/updateAudioDialog";
import { mapState } from 'vuex'
export default {
  components: {
    AudioItem,
    UpdateAudioDialog,
  },
  data() {
    return {
      updadeItem: {},
      updateImageDialog: false,
      updateImageItem: null,
      afterUpdateImageURL: null,
      pictureIndex: 0,
      total: 0,
      loading: false,
      //  todo
      model: {
        currPage: 1,
        limit: 12,
        scenarioId: "",
        searchKey: "",
        theme: "",
        timeLength: "",
        category: "",
      },
      modifyModel: {
        name: "",
      },
      stateList: [
        {
          id: "0",
          name: "审核状态",
        },
        {
          id: "1",
          name: "草稿箱",
          color: "rgba(215, 213, 223, 1)",
        },
        {
          id: "2",
          name: "已审核",
          color: "rgba(139, 209, 203, 1)",
        },
        {
          id: "3",
          name: "未通过",
          color: "rgba(248, 205, 227, 1)",
        },
      ],
      audioList: [],
    };
  },
  created() {
    if (!this.$store.state.dict.audioType) {
      this.$store.dispatch("getDicts", ["audioType", "audioTimeLength"]);
    }

    this.getAudioList();
  },
  mounted() {},
  watch: {
    model: {
      handler() {
        this.getAudioList();
      },
      deep: true,
    },
  },
  computed: {
    scenarioList() {
      return this.$store.state.scenarioList;
    },
    ...mapState({
      list1: state => state.dict.list1,
      list4: state => state.dict.list4,
      audioTypeList: state => state.dict.audioType,
      audioTimeLengthList: state => state.dict.audioTimeLength,
    })
  },
  methods: {
    fomatType(type) {
      return this.list1.find(item => item.dictValue == type).dictLabel
    },
    openDialog(item) {
      item.theme = item.theme + "";
      item.timeLength = item.timeLength + "";
      item.type = item.type + "";
      this.updadeItem = item;
      this.$refs.audioDialog.updateAudioDialog = true;
    },
    returnState(state) {
      let re = {};
      this.stateList.forEach((res) => {
        if (res.id == state) {
          re = {
            name: res.name,
            color: res.color,
          };
        }
      });
      return re;
    },
    uploadSuccess(e) {
      this.afterUpdateImageURL = e.url;
    },
    pictureTypeName(id) {
      let re = "";
      this.list4.forEach((element) => {
        if (element.dictValue == id) {
          re = element.dictLabel;
        }
      });
      return re;
    },
    async getAudioList() {
      this.loading = true
      const res = await this.$api.source.getAudioList(this.model);
      this.audioList = res.data;
      this.total = res.count;
       this.loading = false
    },
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 198px;
  height: 148px;
}
.avatar-uploader .el-upload:hover {
  border-color: #7f76bd;
}
.avatar {
  width: 198px;
  height: 148px;
  display: block;
}
</style>

<style lang='scss' scoped>
.scgl {
  width: 100%;
  height: 100%;
  .scgl-row1 {
    display: flex;
    justify-content: space-between;
    .right {
      display: flex;
    }
  }

  .row3 {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }
  .progress-wrap {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
.scgl-row2 {
  margin-top: 10px;
  width: 100%;
  height: calc(100% - 57px);
  .scgl-row2-card {
    padding: 10px 0;
    height: 100%;
    overflow-y: scroll;
    .scgl-card-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      .scgl-item {
        margin-bottom: 40px;
        margin-right: 20px;
        width: 198px;
        .scgl-item-image {
          height: 148px;
          background: rgba(236, 234, 250, 1);
          border-radius: 4px;
          position: relative;
          .scgl-left {
            position: absolute;
            top: -5px;
            left: 0;
          }
          .right {
            position: absolute;
            top: 0;
            right: 0;
            height: 20px;
            width: 54px;
            font-size: 14px;
            color: #fff;
            text-align: center;
            line-height: 20px;
            border-radius: 4px;
          }
          .item-image-mask {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: rgb(0, 0, 0, 0.8);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            border-radius: 4px;
            color: #fff;
          }
        }
        .item-message {
          font-size: 14px;
          .row1 {
            margin-top: 10px;
          }
          .row2 {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
  }
}
</style>