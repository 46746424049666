<!--  -->
<template>
  <!-- 修改图片弹窗 -->
  <el-dialog center width="960px" :visible.sync="updateAudioDialog">
    <div
      v-if="updateAudioDialog"
      style="display: flex; align-items: center; justify-content: space-around"
    >
      <AudioItem :audioItem="updadeItem" />
      <div style="font-size: 28px">替换成：</div>
      <UploadItem
        :uploadType="1"
        @uploadSuccess="uploadSuccess"
        :type="2"
        class="avatar-uploader"
      >
        <AudioItem
          :isShowTips="!afterupdadeItem.audioUrl"
          :audioItem="afterupdadeItem"
        />
      </UploadItem>
    </div>
    <div class="flex-cloumn flex-as">
      <div
        style="
          display: flex;
          justify-content: center;
          margin-top: 20px;
          align-items: center;
        "
      >
        <el-tag size="small" style="width: 96px" effect="dark">名称</el-tag>
        <el-input
          v-model="updadeItem.audioName"
          placeholder="请输入素材名"
          size="mini"
          style="width: 212px; margin-left: 12px"
        ></el-input>
      </div>

      <div
        style="
          display: flex;
          justify-content: center;
          margin-top: 8px;
          align-items: center;
        "
      >
        <el-tag size="small" style="width: 96px" effect="dark">关联剧本</el-tag>
        <el-select
          v-model="updadeItem.scenarioId"
          style="width: 212px; margin-left: 12px"
          size="mini"
          placeholder="剧本"
        >
          <el-option
            v-for="(option, oi) in scenarioList"
            :key="oi"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>
      </div>
      <div style="display: flex; justify-content: center; margin-top: 8px">
        <el-select
          v-model="updadeItem.type"
          style="width: 100px"
          size="mini"
          placeholder="类别"
        >
          <el-option
            v-for="(option, oi) in audioTypeList"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>

        <el-select
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="时长"
          v-model="updadeItem.timeLength"
        >
          <el-option
            v-for="(option, oi) in audioTimeLengthList"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>

        <el-select
          style="width: 100px; margin-left: 10px"
          size="mini"
          placeholder="题材"
          v-model="updadeItem.theme"
        >
          <el-option
            v-for="(option, oi) in list1"
            :key="oi"
            :label="option.dictLabel"
            :value="option.dictValue"
          ></el-option>
        </el-select>
      </div>
    </div>
    <div slot="footer">
      <el-button @click="updateAudio">保存</el-button>
    </div>
  </el-dialog>
</template>

<script>
import AudioItem from "@compts/common/audioItem.vue";
import UploadItem from "@compts/common/UploadItem";
export default {
  components: {
    AudioItem,
    UploadItem,
  },
  props: {
    updadeItem: {
      type: Object,
    },
  },
  data() {
    return {
      afterupdadeItem: {
        fileInfoId: "",
        audioUrl: "",
        audioTotalTime: 0,
      },
      afterUpdateAudioURL: "",
      accessToken: localStorage.getItem("access_token"),
      updateAudioDialog: false,
      model: {
        audioName: "",
        audioTotalTime: 0,
        fileInfoId: "",
        id: 0,
        scenarioId: 0,
        theme: 0,
        timeLength: 0,
        type: 0,
      },
    };
  },
  //监听属性 类似于data概念
  computed: {
    scenarioList() {
      return this.$store.state.scenarioList;
    },
    list1() {
      return this.$store.state.dict.list1;
    },
    list2() {
      return this.$store.state.dict.list2;
    },
    list3() {
      return this.$store.state.dict.list3;
    },
    list4() {
      return this.$store.state.dict.list4;
    },
    audioTypeList() {
      return this.$store.state.dict.audioType;
    },
    audioTimeLengthList() {
      return this.$store.state.dict.audioTimeLength;
    },
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  //监控data中的数据变化
  watch: {
    updateAudioDialog(v) {
      if(v) {
        this.afterupdadeItem = this.$options.data().afterupdadeItem
      }
    }
  },

  methods: {
    uploadSuccess(item, v) {
      const res = v.datas;
      this.afterupdadeItem.audioUrl = res.url;
      this.afterupdadeItem.fileInfoId = res.id;
      this.afterupdadeItem.audioTotalTime = 0;
    },
    async updateAudio() {
      const {
        audioName,
        audioTotalTime,
        fileInfoId,
        id,
        scenarioId,
        theme,
        timeLength,
        type,
      } = this.updadeItem;

       await this.$api.source.unpdateAudio([
        {
          audioName,
          audioTotalTime: Math.round(
            this.afterupdadeItem.fileInfoId
              ? this.afterupdadeItem.audioTotalTime
              : audioTotalTime
          ),
          fileInfoId: this.afterupdadeItem.fileInfoId || fileInfoId,
          id,
          scenarioId,
          theme: +theme,
          timeLength: +timeLength,
          type: +type,
        },
      ]);

      this.afterupdadeItem = this.$options.data().afterupdadeItem;
      this.updateAudioDialog = false;
      this.$emit("updateAudio");
    },
  },
  destroyed() {},
};
</script>
    
<style lang='' scoped>
</style>